<template>
  <div>
    <vs-row>
      <vs-col vs-w="12" class="pr-5">
        <vx-card>
          <vs-row>
            <vs-col vs-w="6" class="pr-5">
              <div>
                <vs-input
                  class="w-full"
                  v-validate="'required'"
                  size="large"
                  label-placeholder="First Name*"
                  v-model="formData.firstName"
                  name="firstName"
                />
                <span class="text-danger text-sm" v-show="errors.has('firstName')">{{
              errors.first("firstName")
            }}</span>
              </div>
            </vs-col>
            <vs-col vs-w="6">
              <div>
                <vs-input
                  class="w-full"
                  v-validate="'required'"
                  size="large"
                  label-placeholder="Last Name*"
                  v-model="formData.lastName"
                  name="lastName"
                />
                <span class="text-danger text-sm" v-show="errors.has('lastName')">{{
              errors.first("lastName")
            }}</span>
              </div>
            </vs-col>



            <vs-col vs-w="6" class="pr-5">
              <div>
                <vs-input
                  class="w-full"
                  v-validate="'required'"
                  size="large"
                  label-placeholder="Phone Number*"
                  v-model="formData.phoneNumber"
                  name="phoneNumber"
                  maxlength="10"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('phoneNumber')"
                >{{ errors.first("phoneNumber") }}</span
                >
              </div>
            </vs-col>

            <vs-col vs-w="6">
              <div>
                <vs-input
                  class="w-full"
                  v-validate="'required|email'"
                  size="large"
                  label-placeholder="Email*"
                  v-model="formData.email"
                  name="email"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('email')"
                >{{ errors.first("email") }}</span
                >
              </div>
            </vs-col>
          </vs-row>
        </vx-card>
      </vs-col>
    </vs-row>

    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>

    <pop-up :errorsList="errors" form-name="UserForm"></pop-up>
    <vs-row class="mt-5">
      <vs-col vs-w="12" class="flex">
        <vs-button class="ml-auto" @click="saveUser">Save</vs-button>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import { TheMask } from "vue-the-mask";

const dictionary = {
  en: {
    attributes: {
      phoneNumber: "phone number",
      firstName: "first name",
      lastName: "last name",
    },
    messages: {
      regex: () =>
        "Phone number should start with 04 and should be 10 digits"
    }
  }
};
Validator.localize(dictionary);
export default {
  components: { TheMask },
  data() {
    return {
      serverUrl: process.env.VUE_APP_API_URL,
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      isSaved: false,
      nextObj: '',// determines the next route,
      formData: {
        firstName: "",
        lastName: "",
        address: "",
        phoneNumber: "",
        email: "",
        fullName: "",
      },
      phoneNumber: "",
    };
  },
  methods: {
    ...mapActions("page", ["fetchPagesById", "updatePageById", "createPage"]),
    ...mapActions("partner", ["fetchStaffDetailsById", "updateStaffDetailsById", "createStaff"]),
    popActions(event) {
      switch (event) {
        case "leave":
          this.nextObj()
          break;
        case "save":
          this.popupActive = false;
          this.saveUser();
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.popupActive = false;
      }
    },
    async getUserById(id) {
      await this.fetchStaffDetailsById(id)
        .then(result => {
          
          this.formData.firstName = result.data.data.firstName;
          this.formData.lastName = result.data.data.lastName;
          this.formData.email = result.data.data.email;
          this.formData.address = result.data.data.address;
          this.formData.phoneNumber = result.data.data.phoneNumber;
          /*if (result.data.data.phoneNumber) {
            const x = result.data.data.phoneNumber
              .replace(/\D/g, "")
              .match(/(\d{4})(\d{3})(\d{3})/);
            this.phoneNumber = `${x[1]} ${x[2]} ${x[3]}`;
          }*/
        })
        .catch(err => {});
    },
    async saveUser() {
      this.$vs.loading();
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.formData.fullName = this.formData.firstName + " "+this.formData.lastName;

          let obj = {
            id: this.$route.params.id,
            data: this.formData,
          };

          if (this.$route.params.id) this.updateUser(obj);
          else this.storeUser(obj);
          this.$vs.loading.close();
          this.isSaved = true;
        }
        this.$vs.loading.close();
      });
      this.$vs.loading.close();
    },
    async updateUser(obj) {
      this.$vs.loading();

      await this.updateStaffDetailsById(obj)
        .then(result => {
          this.$vs.loading.close();
          this.showMessage(
            "Successful",
            "User has been updated successfully.",
            "success",
            "icon-check-circle"
          );
          this.isSaved = true;
          this.$router.push({ name: "partner-users" });
        })
        .catch(err => {
          this.$vs.loading.close();
          this.showMessage(
            "Error",
            err.response.data.message,
            "danger",
            "icon-times"
          );
          
        });
    },
    async storeUser(obj) {
      this.$vs.loading();

      await this.createStaff(obj)
        .then(result => {
          this.$vs.loading.close();
          this.showMessage(
            "Successful",
            "User has been created successfully.",
            "success",
            "icon-check-circle"
          );
          this.isSaved = true;
          this.$router.push({ name: "partner-users" });
        })
        .catch(err => {
          
          this.$vs.loading.close();
          this.showMessage(
            "Error",
            err.response.data.message,
            "danger",
            "icon-times"
          );
          
        });
    },
    showMessage(
      title = "Successful",
      message = "Task completed",
      color = "success",
      icon = "icon-check-circle"
    ) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,

      });
    }
  },
  beforeRouteLeave(to, from, next) {  //check here
    if(this.isFormDirty && !this.isSaved){
      this.nextObj = next;
      this.popupActive = true;
    }
    else{
      next();
    }
  },
  mounted() {
    if (this.$route.params.id) this.getUserById(this.$route.params.id);
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    }
  }
};
</script>
